import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const apiKey = process.env.REACT_APP_API_KEY;

// Estilos de la página
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
  padding: 0 2rem;
  font-family: "Font";
`;

const EventCard = styled.div`
  display: flex;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgb(87, 87, 86);
  cursor: pointer; 
  border: 1px solid rgb(87, 87, 86);

  @media (min-width: 1025px) {
    padding: 1rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border: 1px solid #0505565e;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
`;

const EventImage = styled.img`
  width: 300px;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 80%;
    height: auto;
  }
`;

const EventInfo = styled.div`
  padding: 15px;
  flex: 1;
`;

const EventTitle = styled.h3`
  margin-top: 0;
  color: #050556;
`;

const EventDate = styled.p`
  color: #666;
`;

const LoadingTextWrapper = styled.div`
  font-size: 1.5rem;
  color: #575756;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  width: 10ch;
  animation: ${keyframes`
    from { width: 0; }
    to { width: 10ch; }
  `} 2s steps(10) infinite alternate, ${keyframes`
    50% { border-color: transparent; }
  `} 0.75s step-end infinite;
`;

const CenteredText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #050556;
  height: 100%;
  text-align: center;
  margin-top: 5rem;
`;

const StyledSeparator = styled(Separador)`
  width: 85%; // Ocupa casi todo el ancho
  margin: 10px auto; // Centrado y con margen superior e inferior
  display: flex;

  @media (min-width: 1025px) {
    display: none; // Oculto en pantallas de escritorio
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #050556;
  font-weight: bold;
`;

const LoadingText = () => {
  return <LoadingTextWrapper>Cargando...</LoadingTextWrapper>;
};

function Tickets() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);  // Estado de carga
  const [error, setError] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Mostrar el popup una vez por sesión
  useEffect(() => {
    const lastPopupTime = localStorage.getItem('lastPopupTimeTickets');
    const now = new Date().getTime();
  
    // Si no ha pasado el tiempo o no se ha mostrado nunca
    if (!lastPopupTime || now - lastPopupTime > 4 * 60 * 60 * 1000) { // 4 horas en milisegundos
      setTimeout(() => setPopupVisible(true), 2000); // Muestra el popup después de 2 segundos
      localStorage.setItem('lastPopupTimeTickets', now); // Guarda el tiempo actual en localStorage
    }
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!acceptedTerms) {
      setErrorMessage('Debe aceptar los términos y condiciones.');
      return;
    }

    if (!email) {
      setErrorMessage('Debe ingresar un correo electrónico válido.');
      return;
    }

    try {
      const response = await fetch('https://backaleron.aleronclub.com/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setPopupVisible(false);
        toast.success('Suscripción exitosa', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        const data = await response.json();
        setErrorMessage('Hubo un problema al suscribirse. Intente de nuevo.');
        console.error(data);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Hubo un problema al conectarse con el servidor.');
    }
  };

  // Cargar eventos desde la API
  useEffect(() => {
    const fetchEvents = async () => {
      try {

        const response = await fetch('https://backaleron.aleronclub.com/api/events', {
          
          headers: { 'Authorization': apiKey },
        });
        if (!response.ok) throw new Error('No se pudieron cargar los eventos');
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        setError(true);
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (code) => {
    window.location.href = `https://www.fourvenues.com/aleron-club/${code}`;
  };

  return (
    <PageContainer>
      <Navbar />
      <Content style={{ marginTop: "1rem" }}>
        <h1 style={{
          display: "flex",
          justifyContent: "center",
          fontFamily: "Font",
          color: "#575756",
          fontSize: "20px",
          marginBottom: "1rem",
        }}>
          TICKETS
        </h1>
        <StyledSeparator />
        {error && <p>Error al cargar eventos. Inténtalo más tarde.</p>}

        {!loading && events.length === 0 && (
          <CenteredText>
            Próximamente
          </CenteredText>
        )}

        {!loading && events.length > 0 && events.map(event => (
          <EventCard key={event._id} onClick={() => handleEventClick(event.code)}>
            <EventImage src={event.image_url} alt={event.name} />
            <EventInfo>
              <EventTitle>{event.name}</EventTitle>
              <EventDate>{new Date(event.display_date).toLocaleDateString('es-ES', {
                weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit',
              })}</EventDate>
            </EventInfo>
          </EventCard>
        ))}
      </Content>
      <Footer />

      {/* Popup */}
      <PopupOverlay show={popupVisible}>
        <PopupContent>
          <CloseButton onClick={() => setPopupVisible(false)}>×</CloseButton>
          <h2 style={{ color: "#050556", fontFamily: "Font" }}>¡Entérate de todos los eventos!</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Ingresa tu correo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: '90%',
                padding: '10px',
                margin: '10px 0',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: "Font",
              }}
            />
            <div style={{ textAlign: 'left', marginBottom: '10px' }}>
              <input
                type="checkbox"
                id="terms"
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
              />
              <label htmlFor="terms" style={{ fontFamily: "Font", marginLeft: '5px' }}>
                Acepto los términos y condiciones
              </label>
            </div>
            {errorMessage && <p style={{ color: 'red', fontFamily: "Font" }}>{errorMessage}</p>}
            <button
              type="submit"
              style={{
                backgroundColor: '#050556',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '100%',
                fontFamily: "Font",
              }}
            >
              Suscribirme
            </button>
          </form>
        </PopupContent>
      </PopupOverlay>
      <ToastContainer />
    </PageContainer>
  );
}

export default Tickets;
