import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import imagen1 from './galeria/1.jpg';
import imagen2 from './galeria/002.jpg';
import imagen3 from './galeria/0003.jpg';
import imagen4 from './galeria/0004.jpg';
import imagen5 from './galeria/0006.jpg';
import imagen26 from './galeria/26.jpg'
import imagen504 from './galeria/504.jpg'
import imagen23 from './galeria/23.jpg'
import '../fonts/fonts.css';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
`;

const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }

  &:hover div {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 35, 111, 0.7);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-family: 'Anton', sans-serif;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const images = [
  { src: imagen26, link: 'https://drive.google.com/drive/folders/1XifjCa_xSa9HdnKWLm9eMN9-DjynzPA6?usp=sharing', text: '26-04-2024' },
  { src: imagen504, link: 'https://drive.google.com/drive/folders/1KGuQez41vj1fc1QupQ1QR7c20xb-ay1I?usp=sharing', text: '05-04-2024' },
  { src: imagen23, link: 'https://drive.google.com/drive/folders/1bcM02B5Ut03uKMwULMqnMF80G4J0RZUQ?usp=sharing', text: '22-03-2024' },
  { src: imagen2, link: 'https://drive.google.com/drive/folders/1UlPyz5Ehl3iWDlrH24HTOALGFNC3do0Q?usp=sharing', text: '08-03-2024' },
  { src: imagen5, link: 'https://drive.google.com/drive/folders/1PeJqUMjPKF0oVenZVp21rvWI1tNNopOI?usp=drive_link', text: '26-01-2023' },
  { src: imagen4, link: 'https://drive.google.com/drive/folders/1IezZR8pdyHcYoFz1EQvh3UeSGIK42V9C?usp=sharing', text: '15-12-2023' },
  { src: imagen3, link: 'https://drive.google.com/drive/folders/1vnO0QDO0OiVUoHGHAyTIAjMEzvw9m2Y4?usp=sharing', text: '08-11-2023' },
];

function Media() {
  return (
    <PageContainer>
      <Navbar />
      <Content style={{ marginTop: '1rem' }}>
        <h1 style={{
          display: 'flex',
          justifyContent: 'center',
          fontFamily: 'Font',
          color: '#575756',
          fontSize: '20px',
          marginBottom: '1rem'
        }}>
          MEDIA
        </h1>
        <StyledSeparator />
        <Grid>
          {images.map((image, index) => (
            <a key={index} href={image.link} style={{ textDecoration: 'none' }}>
              <GridItem>
                <img src={image.src} alt={`Imagen ${index + 1}`} />
                <Overlay>{image.text}</Overlay>
              </GridItem>
            </a>
          ))}
        </Grid>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default Media;
